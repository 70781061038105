<template>
  <div
    class="modal fade"
    ref="externalPaymentModal"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="externalPaymentModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-xl modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h6 class="modal-title pink" id="staticBackdropLabel">Payment</h6>
        </div>
        <div class="modal-body p-0">
          <!-- <div class="row">
            <div class="col-12"> -->
          <div class="row">
            <div class="col-lg-12">
              <div class="iframe-container" v-if="currentData">
                <iframe
                  width="100%"
                  height="100%"
                  frameborder="0"
                  scrolling="yes"
                  allowtransparency="true"
                  :src="currentData.redirectUrl"
                ></iframe>
              </div>
            </div>
          </div>

          <!-- </div>
          </div> -->
        </div>
        <!-- <div class="modal-footer">
          <button
            type="button"
            class="btn btn-link"
            @click.prevent="closeDialog()"
            :disabled="loadingBtn"
          >
            Close
          </button>

          <button
            :disabled="loadingBtn"
            type="button"
            class="btn btn-dblue px-5"
            @click.prevent="buyLoad()"
          >
            <div
              v-if="loadingBtn"
              class="spinner-border spinner-border-sm"
              role="status"
            >
              <span class="visually-hidden">Loading...</span>
            </div>
            Pay now
          </button>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from "bootstrap";

export default {
  name: "CheckoutSummary",
  components: {},
  data() {
    return {
      loadingBtn: false,

      modal: null,

      mobileNumber: "",
      submitted: false,

      dialog: false,
      resolve: null,
      reject: null,

      currentData: {},
    };
  },
  methods: {
    open(data) {
      this.dialog = true;

      this.currentData = data;
      this.modal.show();
      // this.options = Object.assign(this.options, options);
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    agree() {
      this.resolve(true);
      this.dialog = false;
    },

    cancel() {
      this.resolve(false);
      this.modal.hide();
      this.dialog = false;
    },

    closeDialog() {
      this.$emit("close", false);
    },

    // config() {
    //   const d = new Date();
    //   const header = {
    //     headers: {
    //       "Content-Type": "application/json",
    //       "wsc-timestamp": d.getTime(),
    //       Authorization:
    //         "uklXuIGL6CqMtQD2C8mZNyjZ4yTnfzHAUdUvm5Rsjd7cI7MNf+wQSbtjTS6LWOggwHVnTTgdCQ==",
    //     },
    //   };
    //   return header;
    // },

    // async buyLoad(data) {
    //   this.loadingBtn = true;
    //   const { origin } = window.location;
    //   const payload = {
    //     mobileNumber: this.getUser.data.serialNumber,
    //     accountNumber: this.getUser.data.accountNumber,
    //     skuId: this.$props.modalData.items[0].skuId,
    //     appName: "S2S_WEBSITE",
    //     successUrl: `${origin}/success`,
    //     failedUrl: `${origin}/failed`,
    //   };

    //   this.$store.dispatch("ADD_LOAD", data);
    //   await this.$api2
    //     .post("/buy-load", payload, this.config())
    //     .then((response) => {
    //       const res = response.data;
    //       if (!res.status) {
    //         if (res.data === undefined) {
    //           this.loadingBtn = false;
    //           this.$swal({
    //             title: "Confirmation",
    //             icon: "warning",
    //             text: res.message,
    //             allowOutsideClick: false,
    //           }).then(() => {
    //             // this.logout();
    //             this.$emit("response", "close-dialog");
    //           });
    //           return;
    //         }

    //         if ("hasPending" in res.data) {
    //           this.$swal({
    //             title: "Confirmation",
    //             icon: "info",
    //             text: res.message,
    //             showCancelButton: true,
    //             focusConfirm: false,
    //             confirmButtonText: "Yes",
    //             cancelButtonText: "No",
    //             allowOutsideClick: false,
    //           }).then((result) => {
    //             if (result.isConfirmed) {
    //               payload.force = true;
    //               this.resend(payload);
    //               // console.log(payload);
    //             } else {
    //               this.loadingBtn = false;
    //             }
    //           });
    //         }
    //         // } else {

    //         // }
    //       } else {
    //         this.loadingBtn = false;
    //         this.closeDialog();
    //         window.location = res.data.redirectUrl;
    //       }
    //     })
    //     .catch((e) => {
    //       this.loadingBtn = false;
    //       console.log(e);
    //     });
    // },

    // async resend(payload) {
    //   await this.$api2
    //     .post("/buy-load", payload, this.config())
    //     .then((response) => {
    //       const res = response.data;
    //       // console.log(response);
    //       if (res.status) {
    //         this.loadingBtn = false;
    //         this.closeDialog();
    //         window.location = res.data.redirectUrl;
    //       }
    //     })
    //     .catch((e) => {
    //       this.loadingBtn = false;
    //       console.log(e);
    //     });
    // },
  },
  // props: {
  //   show: {
  //     type: Boolean,
  //   },

  //   modalData: {
  //     type: Object,
  //     default() {
  //       return {};
  //     },
  //   },
  // },

  watch: {
    // show() {
    //   if (this.$props.show) {
    //     this.modals.mobileNumber.show();
    //   } else {
    //     this.modals.mobileNumber.hide();
    //   }
    // },
  },

  mounted() {
    this.modal = new Modal(this.$refs.externalPaymentModal, {
      keyboard: false,
    });

    // if (this.$props.show) {
    //   this.modals.mobileNumber.show();
    // } else {
    //   this.modals.mobileNumber.hide();
    // }
  },

  computed: {
    getUser() {
      return this.$store.getters.GET_USER;
    },
  },
};
</script>

<style scoped>
.modal-header {
  background-color: rgba(0, 0, 0, 0.03) !important;
}

.iframe-container {
  overflow: hidden;
  /* padding-top: 56.25%; 16:9 */
  position: relative;
  height: 100vh;
}

.iframe-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  border: 0;
  width: 100%;
  height: 100%;
}
</style>
